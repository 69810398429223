'use client';
import Pagination from '@/components/Pagination/Pagination';
import SmPagination from '@/components/Pagination/SmPagination';
import { dealLng } from '@/utils';
import { useParams, useRouter } from 'next/navigation';

interface IProps {
  page: number;
  total: number
}

export default function FooterPagination(props: IProps) {

  const params = useParams();
  const router = useRouter();
  const prefix = `${dealLng(params.lng as string)}/home/${params.category[0]}/${params.category[1]}`;

  return (
    <section className='pagination-part'>
      <Pagination curPage={props.page} 
        onChange={ (newPage) => router.push(`${prefix}/${newPage}`) } 
        total={props.total} 
        className="pt-[60px] text-black  pb-[150px] max-md:hidden"></Pagination>
      <SmPagination curPage={props.page} 
        onChange={ (newPage) => router.push(`${prefix}/${newPage}`) } 
        total={props.total} 
        textStyle='text-black'
        inputStyle='bg-[#F6F6F5] rounded-[4px] text-center'
        className='font-medium text-black text-sm px-[15px] pt-[25px] pb-[70px] hidden max-md:flex' />
    </section>
  );
}
